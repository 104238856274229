.banner-row {
  margin: 0 0 30px;
  @include media-breakpoint-up(md) {
    margin: 0;
  }
  .banner {
    margin: 0 0 15px;
   @include media-breakpoint-down(xs) {
      padding-left: 0;
      padding-right: 0;
    }
    @include media-breakpoint-down(sm) {
      padding-right: 0;
      &.first-banner {
        padding-left: 0;
      }
    }
    @include media-breakpoint-up(md) {
      margin: 0;
      padding: 0;
    }
    img {
      width: 100%;
      margin: 0 auto;
    }
    .separator {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}

.banner-md {
  margin: -7px 0 0;
}

.banner-row {
  & > .separator {
    @include media-breakpoint-up(md) {
      &:last-child {
        display: none;
      }
    }
  }
  &:last-child > .separator {
    display: block;
  }
}

#partner-pyramid {
  a {
    text-decoration: none;
  }
  img {
    width: 50%;
    @include media-breakpoint-up(sm) {
      width: auto;
      height: 70px;
    }
  }
  .partner-container {
    height: 130px;
    @include media-breakpoint-up(xs) {
      height: 175px;
    }
    @include media-breakpoint-up(sm) {
      height: 120px;
    }
    a {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      text-align: center;
      &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        height: 100%;
      }
    }
    &#main-partner {
      &-left img,
      &right img {
        height: 50px;
      }
      @include media-breakpoint-up(sm) {
        &-left a {
          margin: 0 50px 0 0;
          text-align: right;
        }
        &-right a {
          margin: 0 0 0 50px;
          text-align: left;
        }
      }
    }
  }
}

#bannerDefaultPos {
  margin: 30px 0 0;
  @include media-breakpoint-up(md) {
    margin: -15px 0 0;
  }
  .visible-xs.visible-sm.separator {
    display: none !important;
  }
}

.separator {
  width: 100%;
  margin: 0 0 20px;
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  @include media-breakpoint-up(md) {
    margin: 0 0 8px;
  }
  > span {
    font-size: 9px;
    position: relative;
    display: inline-block;
    letter-spacing: 0.5px;
    &:before,
    &:after {
      content: "\00A0";
      position: absolute;
      top: 40%;
      width: 9999px;
      height: 1px;
      background: $color-nile-blue;
    }
    &:before {
      right: 100%;
    }
    &:after {
      left: 100%;
    }
  }

    &:first-child {
        margin-top: -6px;
    }

  .separator-heading {
    @include media-breakpoint-up(md) {
      margin: 15px 0 7px;
    }
    &:before {
      right: 100%;
      margin-right: 15px;
    }
    &:after {
      left: 100%;
      margin-left: 15px;
    }
  }
}

.banner-ad-slot {
  margin-bottom: 35px;
  @include media-breakpoint-up(md) {
    margin-bottom: 20px;
  }
  .row {
    margin-left: 0;
    @include media-breakpoint-up(md) {
      margin-right: 0;
    }
  }
  .separator {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}

.banner-ad {
  @include media-breakpoint-up(sm) {
    padding-right: 15px;
    padding-bottom: 15px;
  }
  @include media-breakpoint-up(md) {
    padding-right: 0;
  }
  img {
    width: 100%;
  }
  .ad-rectangle {
    padding-left: 0;
    padding-bottom: 15px;
  }
  //    &.banner-ad-0 {
  //
  //    }
  //    &.banner-ad-1 {
  //        .ad-rectangle {
  //            padding-right: 0;
  //        }
  //    }
}

