.btn {
    padding-bottom: calc(#{$btn-padding-y} - .8%);

    &-sm {
        padding-bottom: calc(#{$btn-padding-y} - 2.2%);
    }

    &-lg {
        padding-bottom: calc(#{$btn-padding-y} - .6%);
    }
}
