/**
 * Swiss PGA Website
 *
 * Variables SCSS
 *
 * @link   https://acribis.ch
 * @author acribis AG <web@acribis.ch>
 * @since  1.0.0
 */

/* DEBUG */

$debug-disable-flexbox:   false;
$debug-disable-css-grid: false;


/* COLORS */

$color-black:          #000000;
$color-gray-darker:    #222222;
$color-gray-dark:      #555555;
$color-gray:           #777777;
$color-gray-light:     #999999;
$color-gray-lighter:   #cccccc;
$color-white:          #ffffff;

$color-apple-blossom:  #a94442;
$color-chambray:       #435a94;
$color-cornflower-blue: #6babe8;
$color-gallery:        #eeeeee;
$color-link-water:     #e4f0f7;
$color-mine-shaft:     #252525;
$color-nile-blue:      #1b334f;
$color-pearl-lusta:    #fcf8e3;
$color-sprout:         #afd39a;
$color-sunglo:         #e66e6c;
$color-silver-chalice: #a4a4a4;
$color-silver: #cccaca;
$color-shadow-green:    #8dc3a9;


$color-base-green:     $color-sprout;
$color-base-red:       $color-sunglo;
$color-base-gray:      $color-gallery;

$colors: (
    'green':      $color-sprout,
    'gray':       $color-silver,
    'red':        $color-sunglo,
);


/* TYPOGRAPHY */

//$font-family-fallback: Arial, Helvetica, sans-serif;

//$font-family-light:    Kohinoor-Light, #{$font-family-fallback};
//$font-family-medium:   Kohinoor-Medium, #{$font-family-fallback};
//$font-family-bold:     Kohinoor-Bold, #{$font-family-fallback};

//$font-family-base:     $font-family-light;

//$headings-font-family: $font-family-medium;
//$headings-font-weight: $font-weight-bold;

//$font-size-base:       13px;
//$font-size-small:      ($font-size-base - 1);
//
//$font-size-h1:         28px;
//$font-size-h2:         21px;
//$font-size-h3:         ($font-size-base + 3); // 16px
//$font-size-h4:         ceil(($font-size-base * 1.25)); // ~18px
//$font-size-h5:         $font-size-base;
//$font-size-h6:         ceil(($font-size-base * 0.85)); //

/* ICONOGRAPHY */

$bootstrap-sass-asset-helper: true;

$fa-font-path:                '~@fortawesome/fontawesome-pro/webfonts';

/* NAVIGATION */

//$nav-link-padding-y:            .75rem; // Bootstrap default: .5rem

/* ALERTS */

/* BADGES */


/* CARDS */

//$card-border-width:  0; // Bootstrap default: $border-width
//$card-border-radius: 0; // Bootstrap default: $border-radius
$card-bg:            $color-link-water; // Bootstrap default: $white

$card-group-margin:  .5rem; // Bootstrap default: ($grid-gutter-width / 2)

/* DROPDOWNS */

$dropdown-border-radius:        0; // Bootstrap default: $border-radius

$dropdown-link-hover-bg:        theme-color(secondary); // Bootstrap default: $gray-100
$dropdown-link-active-color:    initial; // Bootstrap default: $component-active-color
$dropdown-link-active-bg:       transparent; // Bootstrap default: $component-active-bg

$dropdown-item-indent-depths:   3;
$dropdown-item-indent-size:     1rem;

/* PAGINATION */

$pagination-border-radius:          0;

$pagination-hover-bg:               lighten(theme-color(secondary), 3%); // Bootstrap default: $gray-200

$pagination-active-bg:              theme-color(secondary); // Bootstrap default: $component-active-bg


/* FORMS */

$input-border-radius: 0; // Bootstrap default: $border-radius

//$input-btn-padding-y: calc(.375rem - 2px); // Bootstrap default: .375rem

//$card-spacer-y:                     .75rem !default;
//$card-spacer-x:                     1.25rem !default;
//$card-border-width:                 $border-width !default;
//$card-border-radius:                $border-radius !default;
//$card-border-color:                 rgba($black, .125) !default;
//$card-inner-border-radius:          calc(#{$card-border-radius} - #{$card-border-width}) !default;
//$card-cap-bg:                       rgba($black, .03) !default;
//$card-bg:                           $white !default;
//
//$card-img-overlay-padding:          1.25rem !default;
//
//$card-group-margin:                 ($grid-gutter-width / 2) !default;
//$card-deck-margin:                  $card-group-margin !default;
//
//$card-columns-count:                3 !default;
//$card-columns-gap:                  1.25rem !default;
//$card-columns-margin:               $card-spacer-y !default;






/* GRID SYSTEM */

//$grid-gutter-width:    30px;



$grid-column-presets:  (2, 3);

// Navbar collapse
$grid-float-breakpoint: 992px; // bs-default: $screen-sm-min

/* PAGE */

$page-brand-width:                    110px;
$page-brand-height:                   null;
$page-brand-width-xs:                 70px;
$page-brand-height-xs:                null;

//$page-nav-font-size:                  $font-size-base;
//$page-nav-font-size-xs:               ($font-size-base + 2);

$page-nav-item-padding-vertical:      10px;
$page-nav-item-padding-horizontal:    15px;
$page-nav-item-padding-vertical-xs:   8px;
$page-nav-item-padding-horizontal-xs: 15px;

// The delay time to trigger the sub navigation
$page-nav-item-hover-threshold:       150ms;

/* NAVBAR */

$navbar-header-height:             auto;
$navbar-header-height-xs:          50px;

$navbar-toggle-width:              22px;
$navbar-toggle-height:             14px;
$navbar-toggle-margin:             9px 5px 0 0;
$navbar-toggle-padding-horizontal: 10px;
$navbar-toggle-padding-vertical:   9px;

$navbar-toggle-bar-height:         2px;
$navbar-toggle-bar-border-radius:  null;
//$navbar-toggle-bar-color:          $text-color;

/* NAVS */

$nav-item-padding-horizontal:    15px;
$nav-item-padding-vertical:      10px;
$nav-item-padding-horizontal-xs: $nav-item-padding-horizontal;
$nav-item-padding-vertical-xs:   $nav-item-padding-vertical;

//$nav-item-font-size:             $font-size-base;

// Tabs Modifier
$nav-tabs-background-color:       $color-gallery;


/* LANGBAR */

$langbar-item-padding-horizontal:    10px;
$langbar-item-padding-vertical:      5px;
$langbar-item-padding-horizontal-xs: $langbar-item-padding-horizontal;
$langbar-item-padding-vertical-xs:   $langbar-item-padding-vertical;


/* FOOTER */

$footer-background-color: $color-nile-blue;
$footer-text-color:       $color-white;

/* BUTTON */

$btn-border-radius: 0;          // Bootstrap default: $border-radius


$button-group-gutter:             10px;

$button-padding-horizontal:       12px;
$button-padding-vertical:         8px;

$button-background-color:         $color-nile-blue;

$button-border-color:             $button-background-color;
$button-border-radius:            null;

$button-font-family:              $font-family-base;
//$button-font-size:                $font-size-base;
$button-text-color:               $color-white;

$button-accept-background-color:  $color-sprout;
$button-accept-text-color:        $color-black;
$button-decline-border-color:     $button-accept-background-color;

$button-decline-background-color: $color-sunglo;
$button-decline-text-color:       $color-black;
$button-decline-border-color:     $button-decline-background-color;

$button-gray-background-color:    $color-gallery;
$button-gray-text-color:          $color-black;
$button-gray-border-color:        $button-gray-background-color;

/* TABLE */

// Table row
$table-row-spacing:                     10px;
// Table row compact
$table-compact-row-spacing:             ($table-row-spacing / 2);

// Table cell
$table-cell-padding-horizontal:         10px;
$table-cell-padding-vertical:           10px;
// Table cell compact
$table-compact-cell-padding-horizontal: 10px;
$table-compact-cell-padding-vertical:   2px;

// Table compact
$table-compact-row-spacing:             ($table-row-spacing / 2);
$table-compact-cell-padding-horizontal: 10px;
$table-compact-cell-padding-vertical:   2px;

$table-display-cell-padding-horizontal: 5px;
$table-display-cell-padding-vertical:   4px;

$table-cell-padding: 10px;
$table-border-color: transparent;

$table-compact-cell-padding: floor($table-cell-padding * .75) $table-cell-padding;


/* TABS */

$tabs-controls-bg: $color-gallery;

$tabs-control-padding-x: 20px;
$tabs-control-padding-y: 10px;
$tabs-control-font-size: 1rem;
$tabs-control-active-bg: $color-nile-blue;
$tabs-control-active-color: $color-white;


/* TILE */

$tile-spacing: (
    top:    20px,
    right:  15px,
    bottom: 15px,
    left:   15px,
);

$tile-background-color:        $color-link-water;

$tile-link-height:             16px;
$tile-link-animation-duration: 100ms;
$tile-link-background-color:   $color-nile-blue;
$tile-link-color:              $color-white;

/* DROPDOWNS */

$dropdown-padding-horizontal: 12px;
$dropdown-padding-vertical:   6px;
$dropdown-border-color:       $color-gray-lighter;
$dropdown-background-color:   $color-white;

/* CARDS */

$card-padding: 30px 10px 10px;

/* USERBAR */

$userbar-width: 150px;
$userbar-background-color: $color-link-water;
//$userbar-text-color: $text-color;

$userbar-toggle-padding-horizontal:       12px;
$userbar-toggle-padding-vertical:         6px;
//$userbar-toggle-text-color:               $userbar-text-color;
$userbar-toggle-background-color:         $userbar-background-color;
$userbar-toggle-border-color:             $userbar-toggle-background-color;


/* FORMS */

// Form label
//$form-label-font-size:         $font-size-h6;

// Form field box model
$form-field-max-width:          420px;
$form-field-margin-bottom:      .5rem;
$form-field-padding-horizontal: 10px;
$form-field-padding-vertical:   6px;
// Form field border
$form-field-border-radius:      0;
$form-field-border-color:       $color-gray-lighter;
// Form field type
$form-field-color:              $color-gray-dark;
//$form-field-font-size:          ;
$form-field-font-family:        $font-family-base;
// Form field focus
$form-field-focus-border-color: $color-nile-blue;
$form-field-focus-color:        $body-color;
// Form field errors
$form-field-error-border-color: $color-sunglo;
$form-field-error-color:        darken($color-sunglo, 25);
// Form field required
$form-field-required-icon:      url('~@fortawesome/fontawesome-pro/svgs/light/asterisk.svg');
$form-field-required-icon-pos:  top 50% right $form-field-padding-horizontal;
$form-field-required-icon-size: 14px;


// Form button
$form-button-group-width:      120px;


/* ALERTS */

$alert-padding-horizontal: 20px;
$alert-padding-vertical: 15px;
$alert-padding-horizontal-xs: $alert-padding-horizontal;
$alert-padding-vertical-xs: $alert-padding-vertical;


/* MODALS */

$modal-close-color: $color-gray-lighter;


/* BADGE */

$badge-padding-horizontal: 15px;
$badge-padding-vertical: 6px;

$badge-background-color: $color-gray-lighter;

//$badge-color: $text-color;
//$badge-font-size: $font-size-small;

// Badge Group
$badge-group-spacing: 6px;


// Variables
// Color system
// Options
// Spacing

$spacers: map-merge(
    $spacers, (
        15: 15px,
        30: 30px,
    )
);

// Body
// Links

$link-color:       $body-color; // Bootstrap default: theme-color("primary")
$link-hover-color: $link-color; // Bootstrap default: darken($link-color, 15%)

// Paragraphs
// Grid breakpoints
// Grid containers
// Grid columns
// Components
// Fonts
// Tables
// Buttons + Forms
// Buttons
// Forms
// Form validation
// Dropdowns
// Z-index master list
// Navs

$nav-tabs-link-padding-y: .875rem !default;

// Navbar

$navbar-brand-padding-y: 1rem; // Bootstrap default: ($nav-link-height - $navbar-brand-height) / 2

$navbar-toggler-padding-y: 1.125rem; // Bootstrap default: .25rem
$navbar-toggler-font-size: 1.5rem; // Bootstrap default: $font-size-lg

// Pagination
// Jumbotron
// Cards
// Tooltips
// Popovers
// Badges
// Modals
// Alerts
// Progress bars
// List group
// Image thumbnails
// Figures
// Breadcrumbs
// Carousel
// Close
// Code
// Printing

// Shame

$page-header-margin-bottom: 2.5rem;

$sitebar-font-size: $font-size-sm;



/**
 * variables.scss
 *
 * All SCSS variables.
 *
 */

// Color system

$color-gallery:         #eeeeee;
$color-link-water:      #e4f0f7;
$color-nile-blue:       #1b334f;
$color-shadow-green:    #8dc3a9;
$color-silver:          #cccbcb;
$color-sunglo:          #e66e6c;

$theme-colors: map-merge($theme-colors,
    (
        'primary':      $color-nile-blue,
        'primary-alt':  rgba($color-nile-blue, .5),
        'secondary':    $color-gallery,
        'tertiary':     $color-link-water,
        'success':      $color-shadow-green,
        'danger':       $color-sunglo,
        'dark':         $color-silver,
        'black':        $color-black,
    )
);

// Options

$enable-rounded: false; // Bootstrap default: true

// Spacing

$spacers: map-merge(
    (
        15: 15px,
        30: 30px,
    ),
        $spacers
);

// Body

$body-bg: $color-white; // Bootstrap default: $white

// Links

$link-color:        $body-color; // Bootstrap default: theme-color("primary")
$link-hover-color:  $link-color; // Bootstrap default: darken($link-color, 15%)

// Paragraphs
// Grid breakpoints
// Grid containers

$container-max-widths-mod: -20px;

$container-max-widths: (
    md: (720px + $container-max-widths-mod),
    lg: (960px + $container-max-widths-mod),
    xl: (1140px + $container-max-widths-mod),
);

// Grid columns
// Components

$border-color: theme-color(dark);

// Fonts

$font-family-base: Kohinoor, #{$font-family-base}; // Bootstrap default: $font-family-sans-serif

$headings-font-weight: $font-weight-bold;

$small-font-size:  65%; // Bootstrap default: 80%

// Tables
// Buttons + Forms

$input-btn-border-width: 0;

// Buttons
// Forms

$form-check-input-margin-y: .1rem;

$form-select-icon:      url('~@fortawesome/fontawesome-pro/svgs/light/chevron-down.svg');
$form-select-icon-pos:  top 50% right $form-field-padding-horizontal;
$form-select-icon-size: 14px;

// Form validation
// Dropdowns
// Z-index master list
// Navs

$nav-tabs-border-color:         transparent; // Bootstrap default: $gray-300
$nav-tabs-border-width:         0; // Bootstrap default: $border-width
$nav-tabs-border-radius:        0; // Bootstrap default: $border-radius
$nav-tabs-background-color:     theme-color(secondary);
$nav-tabs-link-padding-y:       $nav-link-padding-x;
$nav-tabs-link-padding-x:       .875rem;
$nav-tabs-link-active-color:    darken($color-white, 5%); // Bootstrap default: $gray-700
$nav-tabs-link-active-bg:       theme-color(primary); // Bootstrap default: $body-bg

$nav-sub-hover-threshold:       200ms;

// Navbar

$navbar-brand-padding-y:    1rem; // Bootstrap default: ($nav-link-height - $navbar-brand-height) / 2

$navbar-toggler-padding-y:  1.125rem; // Bootstrap default: .25rem
$navbar-toggler-font-size:  1.5rem; // Bootstrap default: $font-size-lg

$navbar-expand:             lg !default;
$navbar-collapse:           md !default;

// Pagination
// Jumbotron
// Cards

$card-border-width: 0; // Bootstrap default: $border-width

// Tooltips

$tooltip-color: $body-color;
$tooltip-bg: theme-color(dark);
$tooltip-opacity: 1;

$tooltip-arrow-width: 1.2rem;
$tooltip-arrow-height: .6rem;
$tooltip-arrow-color: $tooltip-bg;

// Popovers
// Badges

$badge-padding-y: .5em; // Bootstrap default: .25em

// Modals

$modal-content-border-width: 0;

// Alerts

$alert-border-width: 0;

$alert-bg-level: -6; // Bootstrap default: -10

// Progress bars
// List group
// Image thumbnails
// Figures
// Breadcrumbs
// Carousel

$carousel-indicator-active-bg: theme-color(primary); // Bootstrap default: $white

// Close
// Code
// Printing
// Shame

$page-header-margin-bottom: 2.5rem;

$sitebar-font-size: $font-size-sm;
