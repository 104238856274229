[data-toggle='collapse'] {
    > svg.fa-chevron-right {
        transform: rotate(90deg);

        @at-root .collapsed#{&} {
            transform: none;
        }
    }

    > svg.fa-folder {
        display: none;
    }

    &.collapsed {
        > svg.fa-folder {
            display: block;
        }

        > svg.fa-folder-open {
            display: none;
        }
    }
}
