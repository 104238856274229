.carousel__indicators {
    @extend .carousel-indicators;

    position: relative;
    bottom: auto;

    &_tabs {
        margin-right: -1px;
        margin-left: -1px;

        > li {
            @include hover-focus {
                background-color: theme-color(primary);
            }

            flex-basis: 100%;
            flex-grow: 1;
            width: auto;
            height: auto;
            margin-right: 1px;
            margin-left: 1px;
            padding: .125rem 0;
            padding-bottom: calc(.125rem - 2px);
            transition: background-color 100ms ease-in-out;
            background-color: theme-color(primary-alt);
            color: $color-white;
            font-size: .75rem;
            text-align: center;
            text-indent: 0;
        }

        > li.active {
            background-color: theme-color(primary);
        }
    }
}
