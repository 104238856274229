@mixin page-navbar {
    @include media-breakpoint-up($navbar-expand) {
        position: relative;
        top: auto;
        right: auto;
        left: auto;
        padding-right: 0;
        padding-left: 0;
        border-bottom: 0;
        overflow-y: visible;
    }

    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    padding-right: ($grid-gutter-width / 2);
    padding-left: ($grid-gutter-width / 2);
    border-bottom: 1px solid $color-gallery;
    background-color: $body-bg;
    z-index: ($zindex-dropdown - 1);

    .nav-in & {
        max-height: 100%;
        overflow-y: scroll;
    }
}

.page__navbar {
    @include page-navbar;
}
