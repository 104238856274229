/**
 * components/base.css
 *
 * Project-wide base styles
 *
 */

html, body {
    height: 100%;
}

html {
    @include media-breakpoint-up(sm) {
        font-size: 13px;
    }

    width: 100%;
    font-size: 16px;

    &.nav-in {
        position: fixed;
    }
}

body {
    display: flex;
    flex-direction: column;
}

label {
    margin-bottom: 0;
}

p {
    &:last-child {
        margin-bottom: 0;
    }

    a {
        text-decoration: underline;
    }
}
