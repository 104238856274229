.form-control {
    padding-bottom: calc(#{$input-padding-y} - 2.2%);
}

select.form-control {
    background-image: $form-select-icon;
    background-repeat: no-repeat;
    background-position: $form-select-icon-pos;
    background-size: $form-select-icon-size;
    cursor: pointer;
    appearance: none;
}

.form-check {
    &-label,
    &-input {
        cursor: pointer;
    }
}
