.separator__heading {
    display: inline-block;
    position: relative;
    font-size: 9px;
    letter-spacing: 0.5px;
    line-height: 1;

    &:before,
    &:after {
        position: absolute;
        top: 50%;
        width: 9999px;
        height: 1px;
        background: $color-nile-blue;
        content: '';
    }

    &:before {
        right: 100%;
        margin-right: 15px;
    }

    &:after {
        left: 100%;
        margin-left: 15px;
    }
}
