@mixin valign-child($child-class, $alignment) {
    &::before {
        display: inline-block;
        height: 100%;
        margin-right: -.25em;
        content: '';
        vertical-align: middle;
    }
    > .#{$child-class} {
        display: inline-block;
        vertical-align: $alignment;
    }
}

@mixin valign($child-selector, $alignment) {
    &::before {
        display: inline-block;
        height: 100%;
        //margin-right: -0.275em;
        content: '';
        vertical-align: middle;
    }

    #{$child-selector} {
        display: inline-block;
        vertical-align: $alignment;
    }
}
