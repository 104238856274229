/**
 * components/webfonts.scss
 *
 * Project webfonts.
 *
 */

@font-face {
    font-family: Kohinoor;
    font-weight: 900;
    src: url('../../fonts/Kohinoor/webfonts/36F2E7_0_0.eot');
    src: url('../../fonts/Kohinoor/webfonts/36F2E7_0_0.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Kohinoor/webfonts/36F2E7_0_0.woff2') format('woff2'),
    url('../../fonts/Kohinoor/webfonts/36F2E7_0_0.woff') format('woff'),
    url('../../fonts/Kohinoor/webfonts/36F2E7_0_0.ttf') format('truetype');
}

@font-face {
    font-family: Kohinoor;
    font-weight: $font-weight-bold;
    src: url('../../fonts/Kohinoor/webfonts/36F2E7_1_0.eot');
    src: url('../../fonts/Kohinoor/webfonts/36F2E7_1_0.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Kohinoor/webfonts/36F2E7_1_0.woff2') format('woff2'),
    url('../../fonts/Kohinoor/webfonts/36F2E7_1_0.woff') format('woff'),
    url('../../fonts/Kohinoor/webfonts/36F2E7_1_0.ttf') format('truetype');
}

@font-face {
    font-family: Kohinoor;
    font-weight: $font-weight-normal;
    src: url('../../fonts/Kohinoor/webfonts/36F2E7_2_0.eot');
    src: url('../../fonts/Kohinoor/webfonts/36F2E7_2_0.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Kohinoor/webfonts/36F2E7_2_0.woff2') format('woff2'),
    url('../../fonts/Kohinoor/webfonts/36F2E7_2_0.woff') format('woff'),
    url('../../fonts/Kohinoor/webfonts/36F2E7_2_0.ttf') format('truetype');
}
