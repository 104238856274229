@mixin form-field-element {
    @extend .form-control;

    padding-bottom: calc(#{$input-padding-y} - 2px);

    &_select {
        padding-right: ($input-padding-x + 1.1rem);
        background-image: url('~@fortawesome/fontawesome-pro/svgs/light/chevron-down.svg');
        background-repeat: no-repeat;
        background-position: top 50% right $input-padding-x;
        background-size: 1rem;
        cursor: pointer;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
    }

    &_compact {
        $multiplier: .8;

        height: auto;
        padding-top: ($input-padding-y * $multiplier);
        padding-right: (($input-padding-x * $multiplier) + 1.1rem);
        padding-bottom: calc(#{$input-padding-y * $multiplier} - 2px);
        padding-left: ($input-padding-x * $multiplier);
        background-position: top 50% right ($input-padding-x * $multiplier);
        font-size: $font-size-sm;
    }

    &_has-errors {
        @extend .is-invalid;
    }

    @at-root select#{&} {
        @extend .form__field_select;
    }

    @at-root select#{&}_compact {
        @extend .form__field_compact;
    }
}

.form__field {
    @include form-field-element;
}
