.embed-container {
    margin: -1px;

    > * {
        width: calc(100% + 2px) !important; // sass-lint:disable-line no-important
    }

    iframe,
    .twitter-tweet {
        margin: 0 !important; // sass-lint:disable-line no-important

        .card & {
            border: 0 !important; // sass-lint:disable-line no-important
        }
    }
}
