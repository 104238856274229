@mixin list-unstyled {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

@mixin anchor-unstyled($color: $body-color) {
    color: $color;
    text-decoration: none;
}
