@mixin pagination-item-element {
    @extend .page-item;

    > a {
        @extend .page-link;

        text-decoration: none;
    }

    &:first-child > a {
        @include border-left-radius($pagination-border-radius);

        margin-left: 0;
    }

    &:last-child > a {
        @include border-right-radius($pagination-border-radius);
    }

    &_active > a {
        border-color: $pagination-active-border-color;
        background-color: $pagination-active-bg;
        color: $pagination-active-color;
        //font-family: $font-family-medium;
        //font-weight: normal;
        z-index: 1;
    }
}

.pagination__item {
    @include pagination-item-element;
}
