/**
 * app.scss
 *
 * The entry point for the scss.
 *
 */

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

@import 'functions/functions';
@import 'variables';
@import 'mixins';

@import 'vendor';

@import './components/base.scss';
@import './components/typography.scss';
@import './components/webfonts.scss';
@import './components/transitions.scss';
@import './components/embed.scss';
@import './components/forms.scss';
@import './components/buttons.scss';

@import '../legacy/banner';

@import '../modules/base';
@import '../modules/type';

@import '../modules/ad/ad';
@import '../modules/address/address';
@import '../modules/alert/alert';
@import '../modules/badge-group/badge-group';
@import '../modules/badge/badge';
//@import '../modules/button-group/button-group';
@import '../modules/button/button';
//@import '../modules/calendar/calendar';
//@import '../modules/call-to-action/call-to-action';
@import '../modules/card/card';
@import '../modules/carousel/carousel';
//@import '../modules/content/content';
@import '../modules/dropdown/dropdown';
@import '../modules/footer/footer';
@import '../modules/form/form';
//@import '../modules/grid/grid';
@import '../modules/hdc-widget/hdc-widget';
@import '../modules/header/header';
//@import '../modules/icon/icon';
@import '../modules/image/image';
@import '../modules/langbar/langbar';
@import '../modules/link/link';
@import '../modules/list/list';
@import '../modules/main/main';
@import '../modules/modal/modal';
@import '../modules/nav/nav';
@import '../modules/navbar/navbar';
@import '../modules/sitebar/sitebar';
@import '../modules/partner-logo/partner-logo';
@import '../modules/page/page';
@import '../modules/pagination/pagination';
//@import '../modules/pro-nav/pro-nav';
//@import '../modules/profile/profile';
//@import '../modules/report/report';
@import '../modules/separator/separator';
@import '../modules/table/table';
@import '../modules/tabs/tabs';
//@import '../modules/tile/tile';
//@import '../modules/toggle/toggle';
@import '../modules/userbar/userbar';
//
@import 'components/shame';

@import 'utilities';
