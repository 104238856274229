@import 'pagination__item';

@mixin pagination-element {
    margin-top: 1rem;
    margin-bottom: 1rem;

    &_left {
        justify-content: left;
    }

    &_right {
        justify-content: right;
    }

    &_center {
        justify-content: center;
    }
}

.pagination {
    @include pagination-element;
}
