.partner-logo {
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding: 1rem;

    > img {
        width: auto;
        max-width: 150px;
        height: auto;
        max-height: 85px;
    }

    &_large > img {
        max-width: 165px;
        max-height: 100px;
    }
}
