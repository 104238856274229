.underline-none {
    text-decoration: none !important;  // sass-lint:disable-line no-important
}

.leading-none {
    line-height: 1;
}

.leading-small {
    line-height: 1.25;
}

.leading-normal {
    line-height: 1.5;
}

.leading-large {
    line-height: 2;
}

.text-decoration-line-through {
    text-decoration: line-through;
}
